import { memo } from "react";
import "../styles/ProfileChannel.scss";

export interface ProfileChannelProps {
    id: string;
    name: string;
    img: string;
    link: string;
    streaming: boolean;
}

export const ProfileChannel = memo((props: ProfileChannelProps) => {
    const { name, img, link, streaming } = props;

    return (
        <a href={link} className='profile-channel'>
            <img
                src={img}
                alt='Profile picture'
                className={
                    streaming
                        ? "profile-channel--picture"
                        : "profile-channel--picture profile-channel--picture-offline"
                }
            />
            <span className='profile-channel--name'>{name}</span>
        </a>
    );
});
