import { memo, useEffect, useState, useContext } from "react";
import { rarityToColor } from "../components/CardUI";
import { ProfileAbout, ProfileAboutProps } from "../components/ProfileAbout";
import { SideTitle } from "../components/SideTitle";
import { DataStoreContext } from "../components/context/DataStoreContext";
import { TwitchAPIContext } from "../components/context/TwitchAPIContext";
import { DataStore } from "aws-amplify";
import { User } from "../models";

export function About() {
    return (
        <>
            <SideTitle
                title="Qu'est ce que c'est Twitch Card Game ?"
                side='left'
                start
            />
            <p className='informations front'>
                Twitch Card Game est un jeu de cartes virtuelles à collectionner
                qui se joue sur Twitch. Le jeu est basé sur le principe d'écrire
                la commande "*c" dans le chat d'un streamer partenaire, ceux qui
                vous octroient des cartes. Vous pourrez ensuite consulter votre
                collection sur ce site.
            </p>
            <SideTitle
                title='Quels sont les probabilités de recevoir une carte ?'
                side='right'
            />
            <p className='informations front'>
                Il existe actuellement 4 raretés de cartes :{" "}
                <span style={{ color: rarityToColor["COMMON"] }}>Commune</span>
                {", "}
                <span style={{ color: rarityToColor["RARE"] }}>Rare</span>
                {", "}
                <span style={{ color: rarityToColor["EPIC"] }}>Épique</span>
                {", "}
                <span style={{ color: rarityToColor["LEGENDARY"] }}>
                    Légendaire
                </span>
                . Les probabilités de recevoir une carte ainsi que les temps de
                rechargments sont les suivantes :
            </p>
            <div className='table-container front'>
                <table>
                    <thead>
                        <tr>
                            <th>Rareté</th>
                            <th>Probabilité</th>
                            <th>Temps de rechargement</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                <span
                                    style={{ color: rarityToColor["COMMON"] }}>
                                    Commune
                                </span>
                            </td>
                            <td>50%</td>
                            <td>10 minute</td>
                        </tr>
                        <tr>
                            <td>
                                <span style={{ color: rarityToColor["RARE"] }}>
                                    Rare
                                </span>
                            </td>
                            <td>30%</td>
                            <td>15 minutes</td>
                        </tr>
                        <tr>
                            <td>
                                <span style={{ color: rarityToColor["EPIC"] }}>
                                    Épique
                                </span>
                            </td>
                            <td>15%</td>
                            <td>20 minutes</td>
                        </tr>
                        <tr>
                            <td>
                                <span
                                    style={{
                                        color: rarityToColor["LEGENDARY"],
                                    }}>
                                    Légendaire
                                </span>
                            </td>
                            <td>5%</td>
                            <td>30 minutes</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <SideTitle
                title='Comment voir mon temps de rechargement ?'
                side='left'
            />
            <p className='informations front'>
                Pour voir votre temps de rechargement plusieurs solutions
                s'offrent à vous : <br />
                <br />
                <span style={{ fontWeight: "bold" }}>1. </span>Si vous essayez
                de recevoir une carte alors que votre temps de rechargement
                n'est pas terminé, le bot vous dira combien de temps il vous
                reste avant de pouvoir en recevoir une autre. <br />
                <br />
                <span style={{ fontWeight: "bold" }}>2. </span>Vous pouvez aussi
                consulter votre temps de rechargement en utilisant l'extension{" "}
                <a
                    className='link'
                    href='https://chrome.google.com/webstore/detail/twitch-card-game/laohoeeccglcbiobhlgelldoppjijaoo?hl=fr&authuser=0'>
                    Twitch Card Game
                </a>{" "}
                pour Google Chrome. <br />
            </p>
            <SideTitle title='Qui sommes nous ?' side='left' />
            <p className='informations front'>
                Nous sommes un groupe d'amis passionnés par le streaming, Les
                jeux vidéos et les animes. Lorsque l'ont combine tout ça
                ensemble ça donne Twitch Card Game ! Si vous voulez contactez
                l'un d'entre nous, vous pouvez le faire via discord en cliquant
                sur nos images de profils ci-dessous.
            </p>
            <Credits />
        </>
    );
}

const Credits = memo(
    () => {
        const [developers, setDevelopers] = useState<ProfileAboutProps[]>([]);
        const [creators, setCreators] = useState<ProfileAboutProps[]>([]);
        const { changeSync, isReady } = useContext(DataStoreContext);
        const { getUser } = useContext(TwitchAPIContext);

        useEffect(() => {
            if (!isReady) return;
            if (developers.length > 0 || creators.length > 0) return;

            const fetchUsers = async () => {
                const users = await DataStore.query(User, (user) =>
                    user.or((user) => [
                        user.creator.eq(true),
                        user.developer.eq(true),
                    ])
                );
                const developers: ProfileAboutProps[] = [];
                const creators: ProfileAboutProps[] = [];
                for (const user of users) {
                    const { id, profile_image_url, display_name } =
                        await getUser(user.twitchID);
                    const u = {
                        id: id,
                        name: display_name,
                        img: profile_image_url,
                        link: user.discordID
                            ? `https://discordapp.com/users/${user.discordID}`
                            : "",
                    };
                    if (user.developer) {
                        developers.push(u);
                    }
                    if (user.creator) {
                        creators.push(u);
                    }
                }
                setDevelopers(developers);
                setCreators(creators);
            };
            fetchUsers();
        }, [isReady]);

        useEffect(() => {
            if (isReady) return;
            changeSync();
        }, []);

        return (
            <>
                <div className='about-creators'>
                    <h3>Développeur</h3>
                    {developers.map((profile, id) => (
                        <ProfileAbout key={id} {...profile} />
                    ))}
                </div>
                <div className='about-creators'>
                    <h3>Créateurs de carte</h3>
                    {creators.map((profile, id) => (
                        <ProfileAbout key={id} {...profile} />
                    ))}
                </div>
            </>
        );
    },
    () => true
);
