/* eslint-disable @typescript-eslint/no-unused-vars */
import { Outlet, useLocation } from "react-router-dom";
import { BsFillCollectionFill, BsInfoCircle, BsTwitch } from "react-icons/bs";
import { IoSettingsOutline, IoStatsChartSharp } from "react-icons/io5";
import { GiTrade } from "react-icons/gi";
import { AiFillHome } from "react-icons/ai";
import { HeaderButton, HeaderButtonProps } from "../components/HeaderButton";
import "../styles/Root.scss";
import { memo, useContext, useEffect, useMemo } from "react";
import { CollectionContext } from "../components/context/CollectionContext";
import { ThemeContext, themes } from "../components/context/ThemeContext";
import { AuthContext } from "../components/context/AuthContext";

export function Root() {
    const { pathname } = useLocation();
    const { user, connected } = useContext(AuthContext);
    const { theme } = useContext(ThemeContext);
    const { collections } = useContext(CollectionContext);
    const userID = useMemo(() => {
        if (!user) return "";
        return user.username.replace("twitch_", "").toLowerCase();
    }, [user]);

    useEffect(() => {
        const t = themes[theme];
        document.documentElement.style.setProperty(
            "--color-primary",
            t.colors.primary
        );
        document.documentElement.style.setProperty(
            "--color-primary-dark",
            t.colors.dark
        );
        document.documentElement.style.setProperty(
            "--color-primary-darker",
            t.colors.darker
        );
        document.documentElement.style.setProperty(
            "--color-primary-light",
            t.colors.light
        );
        document.documentElement.style.setProperty(
            "--color-primary-lighter",
            t.colors.lighter
        );
    }, [theme]);

    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
        });
    }, [pathname]);

    return (
        <div className='root'>
            <header className='header'>
                <HeaderButton
                    link={connected ? `/collection/${userID}` : "/collection"}
                    label={connected ? userID : "Ma collection"}
                    icon={BsFillCollectionFill}
                    selected={
                        connected
                            ? pathname === `/collection/${userID}`
                            : pathname === "/collection"
                    }
                    user={connected}
                />
                {collections.map((collection) => {
                    if (collection.id === userID) return;
                    return (
                        <HeaderButton
                            key={collection.id}
                            link={`/collection/${collection.id}`}
                            label={collection.id}
                            icon={BsFillCollectionFill}
                            selected={
                                pathname === `/collection/${collection.id}`
                            }
                            user
                        />
                    );
                })}
                <div className='filler' />
                {buttonsBottom.map((button, id) => (
                    <HeaderButton
                        key={id}
                        {...button}
                        selected={pathname === button.link}
                    />
                ))}
            </header>
            <RootContent />
        </div>
    );
}

const RootContent = memo(
    () => {
        return (
            <div className='root-content'>
                <Outlet />
            </div>
        );
    },
    () => true
);

const buttonsBottom: HeaderButtonProps[] = [
    /*{
        link: "/statistics",
        label: "Statistiques",
        icon: IoStatsChartSharp,
    },
    {
        link: "/trade",
        label: "Centre d'échange",
        icon: GiTrade,
    },*/
    {
        link: "/channels",
        label: "Chaines affiliées",
        icon: BsTwitch,
    },
    {
        link: "/about",
        label: "À propos",
        icon: BsInfoCircle,
    },
    {
        link: "/settings",
        label: "Paramètres",
        icon: IoSettingsOutline,
    },
];
