import { Auth, DataStore } from "aws-amplify";
import { createContext, useEffect, useState } from "react";
import { setAdmin as syncAdmin } from "../..";

const AuthContext = createContext(
    {} as {
        connected: boolean;
        streamer: boolean;
        admin: boolean;
        superAdmin: boolean;
        user: any;
        connect: () => void;
        disconnect: () => void;
    }
);

function AuthProvider(props: { children: React.ReactNode }) {
    const { children } = props;
    const [connected, setConnected] = useState(false);
    const [streamer, setStreamer] = useState(false);
    const [admin, setAdmin] = useState(false);
    const [superAdmin, setSuperAdmin] = useState(false);
    const [user, setUser] = useState<any>(undefined);

    function connect() {
        Auth.federatedSignIn({ customProvider: "Twitch" });
    }
    function disconnect() {
        DataStore.clear();
        Auth.signOut();
    }

    useEffect(() => {
        Auth.currentAuthenticatedUser()
            .then((user) => {
                console.log(user);
                setAdmin(
                    user.signInUserSession.accessToken.payload[
                        "cognito:groups"
                    ].includes("ADMIN")
                );
                syncAdmin(
                    user.signInUserSession.accessToken.payload[
                        "cognito:groups"
                    ].includes("ADMIN")
                );
                setStreamer(
                    user.signInUserSession.accessToken.payload[
                        "cognito:groups"
                    ].includes("STREAMER")
                );
                setSuperAdmin(
                    user.signInUserSession.accessToken.payload[
                        "cognito:groups"
                    ].includes("SUPERADMIN")
                );
                setConnected(true);
                setUser(user);
            })
            .catch(() => {
                setAdmin(false);
                setStreamer(false);
                setSuperAdmin(false);
                setConnected(false);
                setUser(undefined);
            });
    }, []);

    return (
        <AuthContext.Provider
            value={{
                connected,
                streamer,
                admin,
                superAdmin,
                user,
                connect,
                disconnect,
            }}>
            {children}
        </AuthContext.Provider>
    );
}

export { AuthContext, AuthProvider };
