import { memo } from "react";
import "../styles/SideTitle.scss";

export const SideTitle = memo(
    (props: {
        title: string;
        side: "right" | "left";
        start?: boolean;
        end?: boolean;
    }) => {
        const { title, side, start = false, end = false } = props;
        return (
            <div
                className={"side-title side-title-" + side}
                style={{
                    marginTop: start ? "0" : undefined,
                    marginBottom: end ? "0" : undefined,
                    borderTopLeftRadius: start ? "0" : undefined,
                    borderTopRightRadius: start ? "0" : undefined,
                    borderBottomLeftRadius: end ? "0" : undefined,
                    borderBottomRightRadius: end ? "0" : undefined,
                }}>
                <h1>{title}</h1>
                {!start && (
                    <span
                        className={
                            "side-title--edge side-title--edge--top-" + side
                        }
                    />
                )}
                {!end && (
                    <span
                        className={
                            "side-title--edge side-title--edge--bottom-" + side
                        }
                    />
                )}
            </div>
        );
    },
    (prevProps, nextProps) =>
        prevProps.title === nextProps.title && prevProps.side === nextProps.side
);
