import { DataStore } from "aws-amplify";
import { memo, useContext, useEffect, useMemo, useRef, useState } from "react";
import { AiFillStar } from "react-icons/ai";
import { FaFilter, FaPlus } from "react-icons/fa";
import { FiUpload } from "react-icons/fi";
import { useParams } from "react-router-dom";
import {
    rarityToColor,
    rarityToString,
    rarityToValue,
    UserCardUI,
    UserCardUIProps,
} from "../components/CardUI";
import { ToggleButton } from "../components/ToggleButton";
import { Card, Universe, UserCard } from "../models";
import "../styles/Collection.scss";
import { SearchInput } from "../components/SearchInput";
import { CollectionContext } from "../components/context/CollectionContext";
import Bounce from "react-activity/dist/Bounce";
import InfiniteScroll from "react-infinite-scroll-component";
import { DataStoreContext } from "../components/context/DataStoreContext";
import { AuthContext } from "../components/context/AuthContext";
import { ConnectButton } from "../components/ConnectButton";
import { TwitchAPIContext } from "../components/context/TwitchAPIContext";
import { CollectionEdit } from "./CollectionEdit";
import { admin } from "..";

enum SortType {
    Amount = 0,
    Rarity = 1,
    Name = 2,
    Date = 3,
}

export function Collection() {
    const { connected, user } = useContext(AuthContext);
    const { twitchID } = useParams<{ twitchID: string }>();
    const { addCollection } = useContext(CollectionContext);
    const { changeSync, isReady, closeDataStore } =
        useContext(DataStoreContext);
    const cards = useRef<UserCardUIProps[]>([]);
    const [releaseMode, setReleaseMode] = useState<boolean>(false);
    const [filteredCards, setFilteredCards] = useState<UserCardUIProps[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [starting, setStarting] = useState<boolean>(true);
    const [focusedCardID, setFocusedCardID] = useState<string>("");
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [smallUpdate, setSmallUpdate] = useState<string>(""); // Used to update the collection when a card is added or removed
    const available = useRef<Available>(
        (localStorage.getItem("available-filter") as Available) ?? "Available"
    );
    const filters = useRef<{
        rarity: RarityFilterProps;
        universes: {
            id: string;
            selected: boolean;
        }[];
        search: string;
    }>({
        rarity: {
            common: localStorage.getItem("rarity.common")
                ? localStorage.getItem("rarity.common") === "true"
                : true,
            rare: localStorage.getItem("rarity.rare")
                ? localStorage.getItem("rarity.rare") === "true"
                : true,
            epic: localStorage.getItem("rarity.epic")
                ? localStorage.getItem("rarity.epic") === "true"
                : true,
            legendary: localStorage.getItem("rarity.legendary")
                ? localStorage.getItem("rarity.legendary") === "true"
                : true,
        },
        universes: [],
        search: localStorage.getItem("search") ?? "",
    });
    const sort = useRef<SortType>(
        parseInt(localStorage.getItem("sort") ?? "0")
    );
    const id = useMemo(() => {
        if (twitchID === undefined) {
            const newID = user?.username?.replace("twitch_", "");
            if (newID === undefined) return undefined;
            window.history.replaceState(null, "", `/collection/${newID}`);
            return newID;
        }
        return twitchID;
    }, [twitchID, user]);

    useEffect(() => {
        if (isReady) {
            closeDataStore();
        }
        setStarting(false);
    }, []);

    useEffect(() => {
        if (starting) return;
        if (id === undefined) {
            setIsLoading(true);
            return;
        }
        setIsLoading(true);
        changeSync({ userID: id });
        return () => {
            //closeDataStore();
        };
    }, [id, starting]);

    useEffect(() => {
        if (starting) return;
        if (!isReady || !isLoading) return;
        if (id === undefined) return;
        const fetchCards = async () => {
            let result: UserCardUIProps[] = [];
            if (cards.current.length === 0) {
                const cards = await DataStore.query(Card);
                for (const card of cards) {
                    result.push({
                        cardID: card.id,
                        amount: 0,
                        informations: [],
                        card: card,
                    });
                }
            } else {
                //Reset the amount of cards
                result = cards.current.map((card) => {
                    return {
                        cardID: card.cardID,
                        amount: 0,
                        informations: [],
                        card: card.card,
                    };
                });
            }
            const userCards = await DataStore.query(UserCard, (c) =>
                c.userID.eq(id)
            );
            console.log(userCards.length + " cards found");
            for (const userCard of userCards) {
                result = result.map((card) => {
                    if (card.cardID === userCard.cardID) {
                        card.amount++;
                        card.informations = card.informations.concat({
                            streamer: userCard.channelID ?? "UNDEFINED",
                            date: userCard.createdAt,
                        });
                    }
                    return card;
                });
            }
            //get all unique universes in result
            filters.current.universes = [];
            for (const card of result) {
                if (
                    !filters.current.universes.some(
                        (universe) => universe.id === card.card?.universeID
                    )
                ) {
                    filters.current.universes.push({
                        id: card.card?.universeID ?? "",
                        selected:
                            localStorage.getItem(
                                "universe-filter-" + card.card?.universeID
                            ) === "true"
                                ? true
                                : false,
                    });
                }
            }
            if (userCards.length > 0) {
                addCollection({
                    id: id,
                    name: "",
                    image: "",
                });
            }
            setIsLoading(false);
            cards.current = result;
            applyFilters(result);
        };
        fetchCards();

        const subscriptionCard = DataStore.observe(Card).subscribe((msg) => {
            if (msg.opType === "UPDATE") {
                let isNewCard = true;
                let abort = false;
                let newCard = cards.current.map((card) => {
                    if (card.cardID === msg.element.id) {
                        isNewCard = false;
                        if (card.card?.updatedAt !== msg.element.updatedAt) {
                            card.card = msg.element;
                        } else {
                            abort = true;
                        }
                    }
                    return card;
                });
                if (abort) return;
                if (isNewCard) {
                    newCard = cards.current.concat({
                        cardID: msg.element.id,
                        amount: 0,
                        informations: [],
                        card: msg.element,
                    });
                }
                cards.current = newCard;
                if (isNewCard) {
                    applyFilters();
                } else {
                    setSmallUpdate(
                        msg.element.id + " " + msg.element.updatedAt
                    );
                }
            }
        });

        const subscriptionUserCard = DataStore.observe(UserCard).subscribe(
            (msg) => {
                if (msg.opType === "INSERT") {
                    const newCard = cards.current.map((card) => {
                        if (card.cardID === msg.element.cardID) {
                            card.amount++;
                            card.informations = card.informations.concat({
                                streamer: msg.element.channelID ?? "UNDEFINED",
                                date: msg.element.createdAt,
                            });
                        }
                        return card;
                    });
                    cards.current = newCard;
                    setSmallUpdate(
                        msg.element.id + " " + msg.element.updatedAt
                    );
                }
            }
        );
        return () => {
            subscriptionCard.unsubscribe();
            subscriptionUserCard.unsubscribe();
        };
    }, [isReady]);

    function applyFilters(c: UserCardUIProps[] = cards.current) {
        let result = c.filter((card) => {
            return (
                (filters.current.rarity.common &&
                    card.card?.rarity === "COMMON") ||
                (filters.current.rarity.rare && card.card?.rarity === "RARE") ||
                (filters.current.rarity.epic && card.card?.rarity === "EPIC") ||
                (filters.current.rarity.legendary &&
                    card.card?.rarity === "LEGENDARY")
            );
        });
        result = result.filter((card) => {
            return filters.current.universes.some(
                (universe) =>
                    universe.id === card.card?.universeID && universe.selected
            );
        });
        result = result.filter((card) => {
            return (
                card.card?.name
                    .toLowerCase()
                    .includes(filters.current.search.toLowerCase()) ||
                card.card?.state
                    .toLowerCase()
                    .includes(filters.current.search.toLowerCase())
            );
        });

        if (admin) {
            result = result.filter((card) => {
                switch (available.current) {
                    case "All":
                        return card;
                    case "Available":
                        return card.card?.available === true;
                    case "Unavailable":
                        return card.card?.available === false;
                }
            });
        } else {
            result = result.filter((card) => {
                return card.amount > 0 || card.card?.available === true;
            });
        }

        result.sort((a, b) => {
            switch (sort.current) {
                case SortType.Amount:
                    return b.amount - a.amount;
                case SortType.Rarity:
                    if (a.amount === 0 && b.amount === 0) {
                        return (
                            rarityToValue[rarityToString(b.card?.rarity)] -
                            rarityToValue[rarityToString(a.card?.rarity)]
                        );
                    }
                    if (a.amount === 0) return 1;
                    if (b.amount === 0) return -1;
                    return (
                        rarityToValue[rarityToString(b.card?.rarity)] -
                        rarityToValue[rarityToString(a.card?.rarity)]
                    );
                case SortType.Name:
                    return a.card?.name.localeCompare(b.card?.name ?? "") ?? 0;
                case SortType.Date:
                    if (a.amount === 0 && b.amount === 0) {
                        return (
                            new Date(b.informations[0]?.date).getTime() -
                            new Date(a.informations[0]?.date).getTime()
                        );
                    }
                    if (a.amount === 0) return 1;
                    if (b.amount === 0) return -1;
                    return (
                        new Date(b.informations[0]?.date).getTime() -
                        new Date(a.informations[0]?.date).getTime()
                    );
            }
        });

        setHasMore(true);
        fetchMoreData(result, []);
        setFilteredCards(result);
    }

    const [hasMore, setHasMore] = useState<boolean>(true);
    const [displayedCards, setDisplayedCards] = useState<UserCardUIProps[]>([]);
    const fetchMoreData = (
        filtered: UserCardUIProps[] = filteredCards,
        displayed: UserCardUIProps[] = displayedCards
    ) => {
        if (filtered.length === 0) {
            setDisplayedCards([]);
            setHasMore(false);
            return;
        }
        if (displayed.length >= filtered.length) {
            setHasMore(false);
            return;
        }
        const next = filtered.slice(displayed.length, displayed.length + 20);
        const newDisplayed = displayed.concat(next);
        setDisplayedCards(newDisplayed);
        if (newDisplayed.length >= filtered.length) {
            setHasMore(false);
        }
    };

    return (
        <div className='collection'>
            <CollectionEdit
                id={focusedCardID}
                setFocusedCardID={(id) => setFocusedCardID(id)}
            />
            <div className='collection-inspect'>
                <CollectionTitle id={id} />
                <div className='collection--filters'>
                    <RarityFilter
                        onChange={(value) => {
                            filters.current.rarity = value;
                            applyFilters();
                        }}
                        defaultValue={filters.current.rarity}
                    />
                    <UniverseFilter
                        onChange={(value) => {
                            filters.current.universes =
                                filters.current.universes.map((universe) => {
                                    universe.selected = value.includes(
                                        universe.id
                                    );
                                    return universe;
                                });
                            applyFilters();
                        }}
                        defaultValue={filters.current.universes.map(
                            (universe) => universe.id
                        )}
                    />
                    <SortCombo
                        onChange={(value) => {
                            localStorage.setItem("sort", value.toString());
                            sort.current = value;
                            applyFilters();
                        }}
                        defaultValue={sort.current}
                    />
                    <SearchInput
                        onChange={(value) => {
                            localStorage.setItem("search", value);
                            filters.current.search = value;
                            applyFilters();
                        }}
                        defaultValue={filters.current.search}
                    />
                    <AdminFilter
                        setFocusedCardID={setFocusedCardID}
                        onChange={(value) => {
                            available.current = value;
                            applyFilters();
                        }}
                        defaultValue={available.current}
                        releaseMode={releaseMode}
                        setReleaseMode={setReleaseMode}
                    />
                </div>
                {isLoading ? (
                    <div className='activity-indicator'>
                        {connected || twitchID !== undefined ? (
                            <Bounce size={25} />
                        ) : (
                            <ConnectButton />
                        )}
                    </div>
                ) : (
                    <InfiniteScroll
                        style={{ overflow: "inherit" }}
                        className='collection--card-list'
                        dataLength={displayedCards.length}
                        next={() => {
                            fetchMoreData();
                        }}
                        hasMore={hasMore}
                        loader={<Bounce size={25} />}>
                        {displayedCards.map((card) => (
                            <UserCardUI
                                key={card.cardID}
                                {...card}
                                amount={
                                    releaseMode
                                        ? card.card?.available
                                            ? 1
                                            : 0
                                        : card.amount
                                }
                                onClick={() => {
                                    if (releaseMode) {
                                        // Change the availability of the card
                                        if (!card.card) return;
                                        DataStore.save(
                                            Card.copyOf(
                                                card.card,
                                                (updated) => {
                                                    updated.releaseDate =
                                                        !updated.released &&
                                                        !updated.available
                                                            ? new Date().getTime()
                                                            : null;
                                                    updated.released =
                                                        !updated.available
                                                            ? true
                                                            : updated.released;
                                                    updated.available =
                                                        !updated.available;
                                                }
                                            )
                                        );
                                    } else {
                                        setFocusedCardID(card.cardID);
                                    }
                                }}
                            />
                        ))}
                    </InfiniteScroll>
                )}
            </div>
        </div>
    );
}

export const CollectionTitle = memo(
    (props: { id: string }) => {
        const { id } = props;
        const [userData, setUserData] = useState<any>(undefined);
        const { getUser } = useContext(TwitchAPIContext);
        useEffect(() => {
            if (id === undefined) {
                setUserData(undefined);
                return;
            }
            getUser(id).then((data) => {
                setUserData(data);
            });
        }, [id]);

        if (userData === undefined) return <h1>Collection</h1>;
        return <h1>Collection de {userData.display_name}</h1>;
    },
    (prevProps, nextProps) => {
        return prevProps.id === nextProps.id;
    }
);

type RarityFilterProps = {
    common: boolean;
    rare: boolean;
    epic: boolean;
    legendary: boolean;
};

function RarityFilter(props: {
    onChange: (value: RarityFilterProps) => void;
    defaultValue: RarityFilterProps;
}) {
    const { onChange, defaultValue } = props;
    const [rarity, setRarity] = useState<RarityFilterProps>(defaultValue);
    const starSize = 20;
    return (
        <div className='collection--rarity-filter'>
            <ToggleButton
                onChange={(value) => {
                    localStorage.setItem("rarity.common", value.toString());
                    onChange({ ...rarity, common: value });
                    setRarity({ ...rarity, common: value });
                }}
                defaultValue={rarity.common}
                color={rarityToColor["COMMON"]}>
                <AiFillStar size={starSize} />
            </ToggleButton>
            <ToggleButton
                onChange={(value) => {
                    localStorage.setItem("rarity.rare", value.toString());
                    onChange({ ...rarity, rare: value });
                    setRarity({ ...rarity, rare: value });
                }}
                defaultValue={rarity.rare}
                color={rarityToColor["RARE"]}>
                <AiFillStar size={starSize} />
                <AiFillStar size={starSize} />
            </ToggleButton>
            <ToggleButton
                onChange={(value) => {
                    localStorage.setItem("rarity.epic", value.toString());
                    onChange({ ...rarity, epic: value });
                    setRarity({ ...rarity, epic: value });
                }}
                defaultValue={rarity.epic}
                color={rarityToColor["EPIC"]}>
                <AiFillStar size={starSize} />
                <AiFillStar size={starSize} />
                <AiFillStar size={starSize} />
            </ToggleButton>
            <ToggleButton
                onChange={(value) => {
                    localStorage.setItem("rarity.legendary", value.toString());
                    onChange({ ...rarity, legendary: value });
                    setRarity({ ...rarity, legendary: value });
                }}
                defaultValue={rarity.legendary}
                color={rarityToColor["LEGENDARY"]}>
                <AiFillStar size={starSize} />
                <AiFillStar size={starSize} />
                <AiFillStar size={starSize} />
                <AiFillStar size={starSize} />
            </ToggleButton>
        </div>
    );
}

function SortCombo(props: {
    onChange: (value: number) => void;
    defaultValue: number;
}) {
    const { onChange, defaultValue } = props;
    const [sort, setSort] = useState(defaultValue);
    const [expanded, setExpanded] = useState(false);
    return (
        <div className='collection--sort-combo'>
            <ToggleButton
                onChange={(value) => {
                    setExpanded(value);
                }}
                value={expanded}>
                <FaFilter size={20} />
            </ToggleButton>
            <div
                className={
                    "collection--sort-combo--list " +
                    (expanded ? " collection--sort-combo--list-show" : "")
                }>
                <ToggleButton
                    onChange={() => {
                        if (sort === SortType.Amount) return;
                        onChange(SortType.Amount);
                        setSort(SortType.Amount);
                    }}
                    value={sort === SortType.Amount}>
                    Quantité
                </ToggleButton>
                <ToggleButton
                    onChange={() => {
                        if (sort === SortType.Rarity) return;
                        onChange(SortType.Rarity);
                        setSort(SortType.Rarity);
                    }}
                    value={sort === SortType.Rarity}>
                    Rareté
                </ToggleButton>
                <ToggleButton
                    onChange={() => {
                        if (sort === SortType.Name) return;
                        onChange(SortType.Name);
                        setSort(SortType.Name);
                    }}
                    value={sort === SortType.Name}>
                    Nom
                </ToggleButton>
                <ToggleButton
                    onChange={() => {
                        if (sort === SortType.Date) return;
                        onChange(SortType.Date);
                        setSort(SortType.Date);
                    }}
                    value={sort === SortType.Date}>
                    Récent
                </ToggleButton>
            </div>
        </div>
    );
}

type Available = "All" | "Available" | "Unavailable";

export const AdminFilter = memo(
    (props: {
        setFocusedCardID: (id: string) => void;
        onChange: (value: Available) => void;
        defaultValue: Available;
        releaseMode: boolean;
        setReleaseMode: (value: boolean) => void;
    }) => {
        const {
            setFocusedCardID,
            onChange,
            defaultValue,
            releaseMode,
            setReleaseMode,
        } = props;
        const [available, setAvailable] = useState(defaultValue);
        const { admin, superAdmin } = useContext(AuthContext);
        if (!admin) return null;
        return (
            <div className='collection--admin'>
                <button
                    onClick={() => setFocusedCardID("NEW")}
                    className='collection--admin--add-button button-outline'>
                    <FaPlus size={20} />
                </button>
                <ToggleButton
                    onChange={() => {
                        if (available === "All") return;
                        setAvailable("All");
                        onChange("All");
                        localStorage.setItem("available-filter", "All");
                    }}
                    value={available === "All"}>
                    Tous
                </ToggleButton>
                <ToggleButton
                    onChange={() => {
                        if (available === "Available") return;
                        setAvailable("Available");
                        onChange("Available");
                        localStorage.setItem("available-filter", "Available");
                    }}
                    value={available === "Available"}>
                    Sortie
                </ToggleButton>
                <ToggleButton
                    onChange={() => {
                        if (available === "Unavailable") return;
                        setAvailable("Unavailable");
                        onChange("Unavailable");
                        localStorage.setItem("available-filter", "Unavailable");
                    }}
                    value={available === "Unavailable"}>
                    Pas sortie
                </ToggleButton>
                {superAdmin && (
                    <ToggleButton
                        onChange={(value) => {
                            setReleaseMode(value);
                        }}
                        value={releaseMode}>
                        <FiUpload size={20} />
                    </ToggleButton>
                )}
            </div>
        );
    },
    (prevProps, nextProps) => prevProps.onChange === nextProps.onChange
);

type UniverseFilterProps = {
    id: string;
    name: string;
    icon: string;
    selected: boolean;
};

function UniverseFilter(props: {
    onChange: (value: string[]) => void;
    defaultValue: string[];
}) {
    const { onChange, defaultValue } = props;
    const [universeFilter, setUniverseFilter] = useState<UniverseFilterProps[]>(
        []
    );

    useEffect(() => {
        if (
            defaultValue === undefined ||
            defaultValue.length === 0 ||
            universeFilter.length > 0
        )
            return;
        const fetchUniverse = async () => {
            const universes = await DataStore.query(Universe);
            const newUniverses = universes
                .filter((universe) => {
                    return defaultValue.includes(universe.id);
                })
                .map((universe) => {
                    return {
                        id: universe.id,
                        name: universe.name,
                        icon: universe.icon ?? "",
                        selected: localStorage.getItem(
                            "universe-filter-" + universe.id
                        )
                            ? localStorage.getItem(
                                  "universe-filter-" + universe.id
                              ) === "true"
                            : true,
                    };
                });
            setUniverseFilter(newUniverses);
            onChange(
                newUniverses
                    .filter((universe) => universe.selected)
                    .map((universe) => universe.id)
            );
        };
        fetchUniverse();
    }, [defaultValue]);

    return (
        <div className='collection--universe-filter'>
            <ToggleButton
                onChange={(value) => {
                    const newUniverseFilter = universeFilter.map((u) => {
                        localStorage.setItem(
                            "universe-filter-" + u.id,
                            value.toString()
                        );
                        return {
                            ...u,
                            selected: value,
                        };
                    });
                    onChange(
                        newUniverseFilter
                            .filter((universe) => universe.selected)
                            .map((universe) => universe.id)
                    );
                    setUniverseFilter(newUniverseFilter);
                }}
                value={universeFilter.every((u) => u.selected)}>
                Tous
            </ToggleButton>
            {universeFilter.map((universe) => (
                <ToggleButton
                    key={universe.id}
                    onChange={(value) => {
                        const newUniverseFilter = universeFilter.map((u) => {
                            if (universe.id === u.id) {
                                return {
                                    ...u,
                                    selected: value,
                                };
                            }
                            return u;
                        });
                        onChange(
                            newUniverseFilter
                                .filter((universe) => universe.selected)
                                .map((universe) => universe.id)
                        );
                        setUniverseFilter(newUniverseFilter);
                        localStorage.setItem(
                            "universe-filter-" + universe.id,
                            value.toString()
                        );
                    }}
                    value={universe.selected}
                    style={{ padding: 0, height: "30px", width: "30px" }}>
                    <img
                        src={universe.icon}
                        alt={universe.name}
                        className='collection--universe-filter--icon'
                    />
                </ToggleButton>
            ))}
        </div>
    );
}
