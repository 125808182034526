import { createContext, useRef } from "react";

const TwitchAPIContext = createContext(
    {} as {
        isStreaming: (id: string) => Promise<boolean>;
        getUser: (id: string) => Promise<any>;
    }
);

function TwitchAPIProvider(props: { children: React.ReactNode }) {
    const { children } = props;
    const twitchOAuth = useRef<string>("");

    async function isStreaming(id: string) {
        if (twitchOAuth.current === "") {
            await requestOAuth();
        }
        const data = await TwitchAPICall(
            `streams?user_id=${id}`,
            twitchOAuth.current
        );
        return data?.data?.length > 0;
    }
    async function getUser(id: string) {
        if (twitchOAuth.current === "") {
            await requestOAuth();
        }
        const data = await TwitchAPICall(`users?id=${id}`, twitchOAuth.current);
        return data?.data[0];
    }

    async function requestOAuth() {
        const data = await TwitchOAuthCall(
            `token?client_id=${process.env.REACT_APP_TWITCH_CLIENT_ID}&client_secret=${process.env.REACT_APP_TWITCH_CLIENT_SECRET}&grant_type=client_credentials`
        );
        twitchOAuth.current = data.access_token;
    }

    return (
        <TwitchAPIContext.Provider value={{ getUser, isStreaming }}>
            {children}
        </TwitchAPIContext.Provider>
    );
}

export { TwitchAPIContext, TwitchAPIProvider };

async function TwitchOAuthCall(url: string) {
    url = "https://id.twitch.tv/oauth2/" + url;
    const response = await fetch(url, {
        method: "POST",
    });
    const data = await response.json();
    return data;
}

async function TwitchAPICall(url: string, twitchOAuth: string) {
    url = "https://api.twitch.tv/helix/" + url;
    const headers = {
        Authorization: "Bearer " + twitchOAuth,
        "Client-Id": process.env.REACT_APP_TWITCH_CLIENT_ID,
    };
    const response = await fetch(url, {
        method: "GET",
        headers: headers as RequestInit["headers"],
    });
    const data = await response.json();
    return data;
}
