import { memo, useState } from "react";
import "../styles/Input.scss";

interface InputProps {
    label: string;
    value: string;
    onChange: (value: string) => void;
    required?: boolean;
    type: "text" | "number" | "color";
}

export const Input = memo(
    (props: InputProps) => {
        const { label, value, onChange, required = false, type } = props;
        const [focused, setFocused] = useState<boolean>(false);
        return (
            <div
                className={
                    "input--container no-select" +
                    (focused ? " input--container--focused" : "") +
                    (required ? " input--container--required" : "")
                }>
                <span
                    className={
                        "input--label-background" +
                        (value.length == 0 && !focused
                            ? " input--label-background--off"
                            : "")
                    }>
                    {label}
                </span>
                <label
                    className={
                        "input--label" +
                        (value.length == 0 && !focused
                            ? " input--label--off"
                            : "")
                    }>
                    {label}
                </label>
                <input
                    className='input'
                    type={type}
                    value={value}
                    onChange={(event) => {
                        switch (type) {
                            case "number":
                                if (
                                    parseInt(event.target.value) !==
                                        undefined &&
                                    !isNaN(parseInt(event.target.value)) &&
                                    parseInt(event.target.value) >= 0
                                ) {
                                    onChange(event.target.value);
                                } else {
                                    onChange("0");
                                }
                                break;
                            default:
                                onChange(event.target.value);
                                break;
                        }
                    }}
                    onFocus={() => {
                        setFocused(true);
                    }}
                    onBlur={() => {
                        setFocused(false);
                    }}
                    required={required}
                />
            </div>
        );
    },
    (prevProps, nextProps) => {
        return (
            prevProps.value === nextProps.value &&
            prevProps.label === nextProps.label &&
            prevProps.required === nextProps.required &&
            prevProps.onChange === nextProps.onChange
        );
    }
);
