import { useContext } from "react";
import { ConnectButton } from "../components/ConnectButton";
import { AuthContext } from "../components/context/AuthContext";
import { themes } from "../components/context/ThemeContext";
import { SideTitle } from "../components/SideTitle";
import { ThemePreview } from "../components/ThemePreview";
import "../styles/Settings.scss";

export function Settings() {
    const { connected } = useContext(AuthContext);
    return (
        <>
            <SideTitle title={"Connexion"} side={"left"} start />
            <div className='column-list front' style={{ gap: "20px" }}>
                <ConnectButton />
                {connected && (
                    <button
                        className='button-fill'
                        onClick={() => {
                            alert(
                                "Cette fonctionnalité n'est pas encore disponible."
                            );
                        }}>
                        Devenir streamer partenaire
                    </button>
                )}
            </div>
            <SideTitle title={"Thèmes"} side={"right"} />
            <ThemeList />
            {false && <BotConfiguration />}
        </>
    );
}

function ThemeList() {
    return (
        <div className='settings--theme-list front'>
            {themes.map((theme, id) => (
                <ThemePreview key={id} id={id} {...theme} />
            ))}
        </div>
    );
}

function BotConfiguration() {
    const { streamer } = useContext(AuthContext);
    if (!streamer) return null;
    return (
        <>
            <SideTitle title={"Configuration du bot"} side={"left"} />
        </>
    );
}
