import { memo, useContext, useEffect, useRef, useState } from "react";
import "../styles/CollectionEdit.scss";
import { CardUI, convertColor, rarityToColor } from "../components/CardUI";
import { Card, Universe } from "../models";
import { DataStore } from "aws-amplify";
import { rarityToString } from "../components/CardUI";
import { FaPlus } from "react-icons/fa";
import { Input } from "../components/Input";
import { ToggleButton } from "../components/ToggleButton";
import { AiFillStar } from "react-icons/ai";
import ReactCrop, { Crop } from "react-image-crop";
import { Storage } from "aws-amplify";
import { AuthContext } from "../components/context/AuthContext";

interface CollectionEditProps {
    id: string;
    setFocusedCardID: (id: string) => void;
}

export const CollectionEdit = memo(
    (props: CollectionEditProps) => {
        const { admin, user } = useContext(AuthContext);
        const { id, setFocusedCardID } = props;
        const [card, setCard] = useState<Card | undefined>(undefined);
        const [amount, setAmount] = useState<number>(
            parseInt(localStorage.getItem("edit-amount") ?? "2")
        );
        const [name, setName] = useState<string>("");
        const [state, setState] = useState<string>("");
        const [rarity, setRarity] = useState<Rarity>("COMMON");
        const [color1, setColor1] = useState<string>("#000000");
        const [color2, setColor2] = useState<string>("#000000");
        const [universeID, setUniverseID] = useState<string>("");
        const [cover, setCover] = useState<string>("");
        const [lockImage, setLockImage] = useState<boolean>(false);
        const [image, setImage] = useState<string>("");
        const [crop, setCrop] = useState<Crop>({
            x: 0,
            y: 0,
            width: 0,
            height: 0,
            unit: "px",
        });

        const [tab, setTab] = useState<Tab>("INFORMATION");
        const [show, setShow] = useState<boolean>(false);
        const [cardChanged, setCardChanged] = useState<boolean>(false);

        useEffect(() => {
            if (id === "NEW") {
                setCard(undefined);
                setShow(true);
                setName("");
                setState("");
                setRarity("COMMON");
                setColor1("#000000");
                setColor2("#000000");
                setUniverseID(process.env.REACT_APP_DEFAULT_UNIVERSE_ID ?? "");
                setCrop({
                    x: 0,
                    y: 0,
                    width: 0,
                    height: 0,
                    unit: "px",
                });
                setImage("");
                setCover("");
                setLockImage(false);
                setCardChanged(false);
                return;
            }
            if (id === "") {
                setShow(false);
                return;
            }
            const fetchCard = async () => {
                const card = await DataStore.query(Card, id);
                setCard(card);
                setShow(card !== undefined);
                setName(card?.name ?? "");
                setState(card?.state ?? "");
                let rarity: Rarity;
                if (card?.rarity === undefined) {
                    rarity = "COMMON";
                } else {
                    rarity = rarityToString(card?.rarity) as Rarity;
                }
                setRarity(rarity);
                setColor1(convertColor(card?.color1 ?? "#000000"));
                setColor2(convertColor(card?.color2 ?? "#000000"));
                setUniverseID(
                    card?.universeID ??
                        process.env.REACT_APP_DEFAULT_UNIVERSE_ID ??
                        ""
                );
                setCrop({
                    x: 0,
                    y: 0,
                    width: 0,
                    height: 0,
                    unit: "px",
                });
                setImage("");
                setCover(card?.cover ?? "");
                setImage(card?.cover ?? "");
                setLockImage(card?.cover !== undefined);
                setCardChanged(false);
            };
            fetchCard();
        }, [id]);

        const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
            e.preventDefault();
            const id = user?.username?.replace("twitch_", "");
            if (name === "" || universeID === "") {
                alert("Le nom et l'univers sont obligatoires");
                return;
            }
            if (card === undefined) {
                console.log("Create new card");
                const subscription = DataStore.observe(Card).subscribe(
                    (msg) => {
                        if (
                            msg.opType === "UPDATE" &&
                            name === msg.element.name
                        ) {
                            setFocusedCardID(msg.element.id);
                            subscription.unsubscribe();
                        }
                    }
                );
                setTimeout(() => {
                    subscription.unsubscribe();
                }, 5000);
                await DataStore.save(
                    new Card({
                        name: name ?? "",
                        state: state ?? "",
                        rarity: rarity ?? "COMMON",
                        color1: color1 ?? "#000000",
                        color2: color2 ?? "#000000",
                        universeID: universeID ?? "",
                        available: false,
                        released: false,
                        creator: [id],
                    })
                );
            } else {
                const key = `cards/cover/${card.id}.png`;
                const sendImage = !lockImage && cover !== "";
                if (sendImage) {
                    const blobImage = await fetch(cover).then((r) => r.blob());
                    await Storage.put(key, blobImage);
                }
                const subscription = DataStore.observe(Card, card.id).subscribe(
                    (msg) => {
                        if (
                            msg.opType === "UPDATE" &&
                            card.updatedAt !== msg.element.updatedAt
                        ) {
                            setCard(msg.element);
                            subscription.unsubscribe();
                        }
                    }
                );
                setTimeout(() => {
                    subscription.unsubscribe();
                }, 5000);
                await DataStore.save(
                    Card.copyOf(card, (updated) => {
                        updated.name = name;
                        updated.state = state;
                        updated.rarity = rarity;
                        updated.color1 = color1;
                        updated.color2 = color2;
                        updated.universeID = universeID;
                        updated.cover = sendImage
                            ? process.env.REACT_APP_S3_URL +
                              "public/" +
                              key +
                              "?t=" +
                              Date.now()
                            : updated.cover;
                        updated.creator = !updated.creator
                            ? [id]
                            : updated.creator.includes(id)
                            ? updated.creator
                            : [...updated.creator, id];
                    })
                );
            }
            setCardChanged(true);
            setTimeout(() => {
                setCardChanged(false);
            }, 5000);
        };

        if (!admin) return null;
        return (
            <div
                className={
                    "collection-edit" +
                    (show ? " collection-edit--visible" : "")
                }>
                <div
                    className={
                        "collection-edit--container" +
                        (show ? " collection-edit--visible" : "")
                    }>
                    <FaPlus
                        size={20}
                        className='search-delete collection-edit--close'
                        onClick={() => {
                            setFocusedCardID("");
                        }}
                    />
                    <CardUI
                        id={""}
                        name={name}
                        state={state}
                        cover={cover}
                        universeID={universeID}
                        color1={color1}
                        color2={color2}
                        amount={amount}
                        rarity={rarity}
                    />
                    <form className='collection-edit--form' onSubmit={onSubmit}>
                        <Tabs
                            tab={tab}
                            setTab={setTab}
                            lockImage={id === "NEW" && card?.id === undefined}
                        />
                        <InputInformations
                            enabled={tab === "INFORMATION"}
                            name={name}
                            setName={setName}
                            state={state}
                            setState={setState}
                            rarity={rarity}
                            setRarity={setRarity}
                        />
                        <InputUniverses
                            enabled={tab === "UNIVERSE"}
                            universeID={universeID}
                            setUniverseID={setUniverseID}
                        />
                        <ImageInput
                            enabled={tab === "IMAGE"}
                            lockImage={lockImage}
                            setLockImage={setLockImage}
                            setCover={setCover}
                            image={image}
                            setImage={setImage}
                            crop={crop}
                            setCrop={setCrop}
                            amount={amount}
                            setAmount={setAmount}
                            color1={color1}
                            setColor1={setColor1}
                            color2={color2}
                            setColor2={setColor2}
                        />
                        <input
                            style={{ width: "calc(100% - 10px)" }}
                            type='submit'
                            className='button-outline'
                            value={"Sauvegarder"}
                        />
                        {cardChanged && (
                            <span className='collection-edit--success'>
                                Le changement a bien été pris en compte
                            </span>
                        )}
                    </form>
                </div>
            </div>
        );
    },
    (prevProps, nextProps) => prevProps.id === nextProps.id
);

interface ImageInputProps {
    enabled: boolean;
    lockImage: boolean;
    setLockImage: (lockImage: boolean) => void;
    setCover: (cover: string) => void;
    image: string;
    setImage: (image: string) => void;
    crop: Crop;
    setCrop: (crop: Crop) => void;
    amount: number;
    setAmount: (amount: number) => void;
    color1: string;
    setColor1: (color: string) => void;
    color2: string;
    setColor2: (color: string) => void;
}

const ImageInput = memo(
    (props: ImageInputProps) => {
        const {
            enabled,
            lockImage,
            setLockImage,
            setCover,
            image,
            setImage,
            crop,
            setCrop,
            amount,
            setAmount,
            color1,
            setColor1,
            color2,
            setColor2,
        } = props;

        const refImage = useRef<HTMLImageElement>(null);

        const handleFile = (e: React.ChangeEvent<HTMLInputElement>) => {
            const file = e.target.files?.[0];
            if (file === undefined) return;
            const reader = new FileReader();
            reader.addEventListener("load", () => {
                setLockImage(false);
                setImage(reader.result as string);
                setCover(reader.result as string);
                setCrop({
                    x: 0,
                    y: 0,
                    width: 0,
                    height: 0,
                    unit: "px",
                });
            });
            reader.readAsDataURL(file);
        };

        const cropImage = () => {
            if (
                crop.width === 0 ||
                crop.height === 0 ||
                refImage.current === null
            ) {
                setCover(image);
                return;
            }

            const canvas = document.createElement("canvas");
            const scaleX =
                refImage.current.naturalWidth / refImage.current.width;
            const scaleY =
                refImage.current.naturalHeight / refImage.current.height;
            canvas.width = crop.width;
            canvas.height = crop.height;
            const ctx = canvas.getContext("2d");

            const pixelRatio = window.devicePixelRatio;
            canvas.width = crop.width * pixelRatio;
            canvas.height = crop.height * pixelRatio;
            if (ctx) {
                ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
                ctx.imageSmoothingQuality = "high";

                ctx.drawImage(
                    refImage.current,
                    crop.x * scaleX,
                    crop.y * scaleY,
                    crop.width * scaleX,
                    crop.height * scaleY,
                    0,
                    0,
                    crop.width,
                    crop.height
                );
            }
            // Converting to base64
            const base64Image = canvas.toDataURL("image/png");
            setCover(base64Image);
            return base64Image;
        };

        if (!enabled) return null;
        return (
            <>
                <Input
                    label='Quantité de cartes (brillance)'
                    value={amount.toString()}
                    onChange={(value) => {
                        setAmount(parseInt(value));
                        localStorage.setItem("edit-amount", value.toString());
                    }}
                    type='number'
                />
                <div className='row'>
                    <Input
                        label='Couleur 1'
                        value={color1}
                        onChange={(value) => setColor1(value)}
                        type='color'
                    />
                    <Input
                        label='Couleur 2'
                        value={color2}
                        onChange={(value) => setColor2(value)}
                        type='color'
                    />
                </div>
                <label
                    htmlFor={"image-input"}
                    className='button-outline collection-edit--image-input-area no-select'>
                    Ajouter une image
                </label>
                <input
                    id={"image-input"}
                    type='file'
                    accept='image/*'
                    onChange={handleFile}
                    className='collection-edit--image-input no-select'
                />
                {image && (
                    <ReactCrop
                        disabled={lockImage}
                        crop={crop}
                        onChange={(c: Crop) => setCrop(c)}
                        onDragEnd={() => cropImage()}
                        aspect={200 / 280}>
                        <img
                            ref={refImage}
                            src={image}
                            alt='Input'
                            className='collection-edit--image-crop no-select'
                        />
                    </ReactCrop>
                )}
            </>
        );
    },
    (prevProps, nextProps) =>
        prevProps.enabled === nextProps.enabled &&
        prevProps.image === nextProps.image &&
        prevProps.crop === nextProps.crop &&
        prevProps.amount === nextProps.amount &&
        prevProps.color1 === nextProps.color1 &&
        prevProps.color2 === nextProps.color2
);

interface InputUniversesProps {
    enabled: boolean;
    universeID: string;
    setUniverseID: (universeID: string) => void;
}

const InputUniverses = memo(
    (props: InputUniversesProps) => {
        const { enabled, universeID, setUniverseID } = props;
        const [universes, setUniverses] = useState<Universe[]>([]);
        useEffect(() => {
            const fetchUniverses = async () => {
                const universes = await DataStore.query(Universe);
                setUniverses(universes);
            };
            fetchUniverses();
        }, []);
        if (!enabled) return null;
        return (
            <div className='collection-edit--universes'>
                {universes.map((universe) => (
                    <UniverseButton
                        key={universe.id}
                        universe={universe}
                        selected={universe.id === universeID}
                        onClick={() => {
                            setUniverseID(universe.id);
                        }}
                    />
                ))}
            </div>
        );
    },
    (prevProps, nextProps) =>
        prevProps.enabled === nextProps.enabled &&
        prevProps.universeID === nextProps.universeID
);

const UniverseButton = memo(
    (props: { selected: boolean; universe: Universe; onClick: () => void }) => {
        const { universe, selected, onClick } = props;
        return (
            <ToggleButton
                style={{ height: "28px" }}
                onChange={onClick}
                value={selected}>
                <img
                    src={universe.icon ?? ""}
                    alt={props.universe.name}
                    className='collection-edit--universe-icon'
                />
                <span className='collection-edit--universe-name'>
                    {universe.name}
                </span>
            </ToggleButton>
        );
    },
    (prevProps, nextProps) => prevProps.selected === nextProps.selected
);

interface InputInformationsProps {
    enabled: boolean;
    name: string;
    setName: (name: string) => void;
    state: string;
    setState: (state: string) => void;
    rarity: Rarity;
    setRarity: (rarity: Rarity) => void;
}

const InputInformations = memo(
    (props: InputInformationsProps) => {
        const { enabled, name, setName, state, setState, rarity, setRarity } =
            props;
        if (!enabled) return null;
        return (
            <>
                <Input
                    label='Nom du personnage'
                    value={name}
                    onChange={(value) => setName(value)}
                    type='text'
                    required
                />
                <Input
                    label='État du personnage'
                    value={state}
                    onChange={(value) => setState(value)}
                    type='text'
                />
                <RarityInput
                    value={rarity}
                    onChange={(value) => {
                        setRarity(value);
                    }}
                />
            </>
        );
    },
    (prevProps, nextProps) =>
        prevProps.enabled === nextProps.enabled &&
        prevProps.name === nextProps.name &&
        prevProps.state === nextProps.state &&
        prevProps.rarity === nextProps.rarity
);

type Rarity = "COMMON" | "RARE" | "EPIC" | "LEGENDARY";

const RarityInput = memo(
    (props: { onChange: (value: Rarity) => void; value: Rarity }) => {
        const { onChange, value } = props;
        const starSize = 20;
        return (
            <div className='collection-edit--rarity'>
                <ToggleButton
                    style={{ flex: 2 }}
                    onChange={() => {
                        onChange("COMMON");
                    }}
                    value={value === "COMMON"}
                    color={rarityToColor["COMMON"]}>
                    <AiFillStar size={starSize} />
                </ToggleButton>
                <ToggleButton
                    style={{ flex: 2 }}
                    onChange={() => {
                        onChange("RARE");
                    }}
                    value={value === "RARE"}
                    color={rarityToColor["RARE"]}>
                    <AiFillStar size={starSize} />
                    <AiFillStar size={starSize} />
                </ToggleButton>
                <ToggleButton
                    style={{ flex: 3 }}
                    onChange={() => {
                        onChange("EPIC");
                    }}
                    value={value === "EPIC"}
                    color={rarityToColor["EPIC"]}>
                    <AiFillStar size={starSize} />
                    <AiFillStar size={starSize} />
                    <AiFillStar size={starSize} />
                </ToggleButton>
                <ToggleButton
                    style={{ flex: 4 }}
                    onChange={() => {
                        onChange("LEGENDARY");
                    }}
                    value={value === "LEGENDARY"}
                    color={rarityToColor["LEGENDARY"]}>
                    <AiFillStar size={starSize} />
                    <AiFillStar size={starSize} />
                    <AiFillStar size={starSize} />
                    <AiFillStar size={starSize} />
                </ToggleButton>
            </div>
        );
    },
    (prevProps, nextProps) =>
        prevProps.value === nextProps.value &&
        prevProps.onChange === nextProps.onChange
);

type Tab = "INFORMATION" | "UNIVERSE" | "IMAGE";

const Tabs = memo(
    (props: { tab: Tab; setTab: (tab: Tab) => void; lockImage: boolean }) => {
        const { tab, setTab, lockImage } = props;
        return (
            <div className='collection-edit--tabs no-select'>
                <span
                    onClick={() => setTab("INFORMATION")}
                    className={
                        "collection-edit--tab" +
                        (tab === "INFORMATION"
                            ? " collection-edit--tab--selected"
                            : "")
                    }>
                    Informations
                </span>
                <span
                    onClick={() => setTab("UNIVERSE")}
                    className={
                        "collection-edit--tab" +
                        (tab === "UNIVERSE"
                            ? " collection-edit--tab--selected"
                            : "")
                    }>
                    Univers
                </span>
                <span
                    onClick={() => {
                        if (!lockImage) setTab("IMAGE");
                    }}
                    className={
                        "collection-edit--tab" +
                        (lockImage ? "-disabled" : "") +
                        (tab === "IMAGE"
                            ? " collection-edit--tab--selected"
                            : "")
                    }>
                    Image
                </span>
                <span
                    className={
                        "collection-edit--tab--indicator collection-edit--tab--indicator-" +
                        tab
                    }
                />
            </div>
        );
    },
    (prevProps, nextProps) =>
        prevProps.tab === nextProps.tab &&
        prevProps.lockImage === nextProps.lockImage
);
