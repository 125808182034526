import { createContext, useState } from "react";

const CollectionContext = createContext(
    {} as {
        collections: UserCollection[];
        addCollection: (collection: UserCollection) => void;
        removeCollection: (collection: UserCollection) => void;
    }
);

export interface UserCollection {
    id: string;
    name: string;
    image: string;
}

function CollectionProvider(props: { children: React.ReactNode }) {
    const { children } = props;
    const [collections, setCollections] = useState<UserCollection[]>(
        localStorage.getItem("collections")
            ? JSON.parse(localStorage.getItem("collections") || "[]")
            : []
    );
    function addCollection(collection: UserCollection) {
        if (collections.find((c) => c.id === collection.id)) return;
        const newCollections = [...collections, collection];
        setCollections(newCollections);
        localStorage.setItem("collections", JSON.stringify(newCollections));
    }
    function removeCollection(collection: UserCollection) {
        const newCollections = collections.filter(
            (c) => c.id !== collection.id
        );
        setCollections(newCollections);
        localStorage.setItem("collections", JSON.stringify(newCollections));
    }

    return (
        <CollectionContext.Provider
            value={{ collections, addCollection, removeCollection }}>
            {children}
        </CollectionContext.Provider>
    );
}

export { CollectionContext, CollectionProvider };
