import { memo, useContext } from "react";
import "../styles/ThemePreview.scss";
import { ThemeContext } from "./context/ThemeContext";

export interface ThemePreviewProps {
    id?: number;
    name: string;
    colors: {
        primary: string;
        dark: string;
        darker: string;
        light: string;
        lighter: string;
    };
}

export const ThemePreview = memo((props: ThemePreviewProps) => {
    const { id = 0, name, colors } = props;
    const { theme, setTheme } = useContext(ThemeContext);
    const selected = theme === id;

    return (
        <div
            className='theme-preview no-select'
            onClick={() => {
                setTheme(id);
            }}>
            <div
                className={
                    "theme-preview--container" +
                    (selected ? " theme-preview--container-selected" : "")
                }
                style={{ backgroundColor: colors.dark }}>
                <span
                    className='theme-preview--side-bar'
                    style={{ backgroundColor: colors.darker }}
                />
                <span
                    className='theme-preview--button theme-preview--button-selected'
                    style={{ backgroundColor: colors.primary }}
                />
                <span
                    className='theme-preview--button theme-preview--button1'
                    style={{ backgroundColor: colors.dark }}
                />
                <span
                    className='theme-preview--button theme-preview--button2'
                    style={{ backgroundColor: colors.dark }}
                />
                <span
                    className='theme-preview--button theme-preview--button3'
                    style={{ backgroundColor: colors.dark }}
                />
                <span
                    className='theme-preview--name'
                    style={{ color: colors.light }}>
                    {name}
                </span>
            </div>
        </div>
    );
});
