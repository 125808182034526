import { memo, useEffect, useMemo, useState } from "react";
import "../styles/ToggleButton.scss";

export interface ToggleButtonProps {
    children: string | JSX.Element | JSX.Element[];
    onChange: (value: boolean) => void;
    defaultValue?: boolean;
    value?: boolean;
    color?: string;
    style?: React.CSSProperties;
}

export const ToggleButton = memo((props: ToggleButtonProps) => {
    const { children, onChange, defaultValue, style } = props;
    const [checked, setChecked] = useState<boolean>(
        defaultValue ?? props.value ?? false
    );
    const color = useMemo(() => {
        if (!checked) return "gray";
        if (props.color) {
            return props.color;
        }
        //get primaty color from css
        return undefined;
    }, [props.color, checked]);

    useEffect(() => {
        if (props.value !== undefined) {
            setChecked(props.value);
        }
    }, [props.value]);

    return (
        <div
            onClick={() => {
                onChange(!checked);
                setChecked(props.value ?? !checked);
            }}
            className={"toggle-button no-select"}
            style={{
                ...style,
                color: color,
                borderColor: color,
                outlineColor: color,
            }}>
            {children}
        </div>
    );
});
