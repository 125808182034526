import ReactDOM from "react-dom/client";
import "./index.scss";
import App from "./App";
import {
    Amplify,
    AuthModeStrategyType,
    Predicates,
    syncExpression,
} from "aws-amplify";
import awsExports from "./aws-exports";
import { Card, Universe, User, UserCard } from "./models";
import "react-activity/dist/library.css";
import "react-image-crop/src/ReactCrop.scss";

export let userID = "";
export const setUserID = (id: string) => {
    userID = id;
};

export let admin = false;
export const setAdmin = (newAdmin: boolean) => {
    admin = newAdmin;
};

Amplify.configure({
    ...awsExports,
    oauth: {
        domain: "twitch-card-game.auth.eu-west-3.amazoncognito.com",
        scope: ["openid"],
        redirectSignIn: process.env.REACT_APP_REDIRECT_SIGN_IN,
        redirectSignOut: process.env.REACT_APP_REDIRECT_SIGN_OUT,
        responseType: "token",
    },
    DataStore: {
        authModeStrategyType: AuthModeStrategyType.MULTI_AUTH,
        syncExpressions: [
            syncExpression(Universe, () => {
                return Predicates.ALL;
            }),
            syncExpression(User, () => {
                return Predicates.ALL;
            }),
            syncExpression(Card, () => {
                return admin
                    ? Predicates.ALL
                    : (user) => user.released.eq(true);
            }),
            syncExpression(UserCard, () => {
                return userID
                    ? (userCard) => userCard.userID.eq(userID)
                    : Predicates.ALL;
            }),
        ],
    },
});

const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
);
root.render(<App />);
