import { memo, useContext } from "react";
import { BsTwitch } from "react-icons/bs";
import { AuthContext } from "./context/AuthContext";

export const ConnectButton = memo(
    () => {
        const { connected, connect, disconnect } = useContext(AuthContext);

        if (connected) {
            return (
                <button
                    className='button-fill'
                    onClick={() => {
                        disconnect();
                    }}
                    style={{
                        backgroundColor: "gray",
                        color: "white",
                    }}>
                    <BsTwitch size={25} />
                    Se déconnecter
                </button>
            );
        } else {
            return (
                <button
                    className='button-fill'
                    onClick={() => {
                        connect();
                    }}
                    style={{
                        backgroundColor: "#9146ff",
                        color: "white",
                    }}>
                    <BsTwitch size={25} />
                    Se connecter
                </button>
            );
        }
    },
    () => true
);
