import { DataStore, Hub } from "aws-amplify";
import { createContext, useEffect, useState } from "react";
import { setUserID } from "../..";

const DataStoreContext = createContext(
    {} as {
        isReady: boolean;
        changeSync: (props?: { userID: string }) => void;
        closeDataStore: () => void;
    }
);

function DataStoreProvider(props: { children: React.ReactNode }) {
    const { children } = props;
    const [isReady, setIsReady] = useState(false);

    async function changeSync(props?: { userID: string }) {
        //if (userID === props.userID) return;
        if (props === undefined) {
            refreshDataStore();
            return;
        }
        setUserID(props.userID ?? "");
        refreshDataStore();
    }

    async function closeDataStore() {
        setUserID("");
        setIsReady(false);
        await DataStore.stop();
    }

    async function refreshDataStore() {
        console.log("changeSync");
        setIsReady(false);
        await DataStore.stop();
        await DataStore.start();
    }

    useEffect(() => {
        const removeListener = Hub.listen("datastore", async (data) => {
            if (data.payload.event === "ready") {
                console.log("ready");
                setIsReady(true);
            }
            //console.log(data);
        });

        return () => {
            removeListener();
        };
    }, []);

    return (
        <DataStoreContext.Provider
            value={{ isReady, changeSync, closeDataStore }}>
            {children}
        </DataStoreContext.Provider>
    );
}

export { DataStoreContext, DataStoreProvider };
