import { createContext, useState } from "react";
import { ThemePreviewProps } from "../ThemePreview";

const ThemeContext = createContext(
    {} as { theme: number; setTheme: (themeId: number) => void }
);

function ThemeProvider(props: { children: React.ReactNode }) {
    const { children } = props;
    const [theme, setTheme] = useState<number>(
        parseInt(localStorage.getItem("theme") || "0")
    );
    function setSaveTheme(themeId: number) {
        setTheme(themeId);
        localStorage.setItem("theme", themeId.toString());
    }

    return (
        <ThemeContext.Provider value={{ theme, setTheme: setSaveTheme }}>
            {children}
        </ThemeContext.Provider>
    );
}

export { ThemeContext, ThemeProvider };

export const themes: ThemePreviewProps[] = [
    {
        name: "Twitch",
        colors: {
            primary: "#a970ff",
            dark: "#1f1f23",
            darker: "#0e0e10",
            light: "#bdbdbd",
            lighter: "#efeff1",
        },
    },
    {
        name: "Discord",
        colors: {
            primary: "#5865f2",
            dark: "#2b2d31",
            darker: "#1e1f22",
            light: "#949ba4",
            lighter: "#edeef0",
        },
    },
    {
        name: "Très sombre",
        colors: {
            primary: "#000000",
            dark: "#000000",
            darker: "#050505",
            light: "#FFFFFF",
            lighter: "#FFFFFF",
        },
    },
    {
        name: "Sépia",
        colors: {
            primary: "#cc9752",
            dark: "#261c0f",
            darker: "#0d0905",
            light: "#ccbaa3",
            lighter: "#ccc3b8",
        },
    },
    {
        name: "Sombre Bleu",
        colors: {
            primary: "#4065ff",
            dark: "#212121",
            darker: "#0a0a0a",
            light: "#aaaaaa",
            lighter: "#ffffff",
        },
    },
    {
        name: "Clair Bleu",
        colors: {
            primary: "#4065ff",
            dark: "#FFFFFF",
            darker: "#e1ecfe",
            light: "#0b0c0d",
            lighter: "#2d2f33",
        },
    },
    {
        name: "Sombre Rouge",
        colors: {
            primary: "#ff8080",
            dark: "#212121",
            darker: "#0a0a0a",
            light: "#aaaaaa",
            lighter: "#ffffff",
        },
    },
    {
        name: "Clair Rouge",
        colors: {
            primary: "#ff8080",
            dark: "#FFFFFF",
            darker: "#e1ecfe",
            light: "#0b0c0d",
            lighter: "#2d2f33",
        },
    },
    {
        name: "Sombre Vert",
        colors: {
            primary: "#4d994d",
            dark: "#212121",
            darker: "#0a0a0a",
            light: "#aaaaaa",
            lighter: "#ffffff",
        },
    },
    {
        name: "Clair Vert",
        colors: {
            primary: "#4d994d",
            dark: "#FFFFFF",
            darker: "#e1ecfe",
            light: "#0b0c0d",
            lighter: "#2d2f33",
        },
    },
    {
        name: "Sombre Jaune",
        colors: {
            primary: "#fcbb62",
            dark: "#212121",
            darker: "#0a0a0a",
            light: "#aaaaaa",
            lighter: "#ffffff",
        },
    },
    {
        name: "Clair Jaune",
        colors: {
            primary: "#fcbb62",
            dark: "#FFFFFF",
            darker: "#e1ecfe",
            light: "#0b0c0d",
            lighter: "#2d2f33",
        },
    },
    {
        name: "Sombre Rose",
        colors: {
            primary: "#ffb7ff",
            dark: "#212121",
            darker: "#0a0a0a",
            light: "#aaaaaa",
            lighter: "#ffffff",
        },
    },
    {
        name: "Clair Rose",
        colors: {
            primary: "#ffb7ff",
            dark: "#FFFFFF",
            darker: "#e1ecfe",
            light: "#0b0c0d",
            lighter: "#2d2f33",
        },
    },
    {
        name: "Sombre Cyan",
        colors: {
            primary: "#4792b3",
            dark: "#212121",
            darker: "#0a0a0a",
            light: "#aaaaaa",
            lighter: "#ffffff",
        },
    },
    {
        name: "Clair Cyan",
        colors: {
            primary: "#4792b3",
            dark: "#FFFFFF",
            darker: "#e1ecfe",
            light: "#0b0c0d",
            lighter: "#2d2f33",
        },
    },
];
