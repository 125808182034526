import { memo, useRef, useState } from "react";
import { BiSearch } from "react-icons/bi";
import { FaPlus } from "react-icons/fa";
import "../styles/SearchInput.scss";

export interface SearchInputProps {
    onChange: (value: string) => void;
    defaultValue?: string;
}

export const SearchInput = memo(
    (props: SearchInputProps) => {
        const { onChange, defaultValue } = props;

        const inputRef = useRef<HTMLInputElement>(null);
        const [value, setValue] = useState(defaultValue ?? "");
        const [isFocused, setIsFocused] = useState(false);

        return (
            <div
                className={
                    "search" +
                    (isFocused || value !== "" ? " search-focused" : "")
                }
                tabIndex={1}
                onFocus={() => {
                    setIsFocused(true);
                    inputRef.current?.focus();
                }}
                onBlur={() => {
                    setIsFocused(false);
                    inputRef.current?.blur();
                }}>
                <BiSearch size={20} className='search-icon' />
                <input
                    ref={inputRef}
                    className='search-input'
                    onChange={(event) => {
                        onChange(event.target.value);
                        setValue(event.target.value);
                    }}
                    value={value}
                />
                {value !== "" && (
                    <FaPlus
                        size={20}
                        className='search-delete'
                        onClick={() => {
                            onChange("");
                            setValue("");
                            setIsFocused(false);
                            inputRef.current?.blur();
                        }}
                    />
                )}
            </div>
        );
    },
    (prevProps, nextProps) => {
        return prevProps.onChange === nextProps.onChange;
    }
);
