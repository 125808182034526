import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "./App.scss";
import { AuthProvider } from "./components/context/AuthContext";
import { CollectionProvider } from "./components/context/CollectionContext";
import { DataStoreProvider } from "./components/context/DataStoreContext";
import { ThemeProvider } from "./components/context/ThemeContext";
import { About } from "./pages/About";
import { Channels } from "./pages/Channels";
import { Collection } from "./pages/Collection";
import { Root } from "./pages/Root";
import { Settings } from "./pages/Settings";
import { WorkInProgress } from "./pages/WorkInProgress";
import { TwitchAPIProvider } from "./components/context/TwitchAPIContext";

const router = createBrowserRouter([
    {
        path: "/",
        element: <Root />,
        children: [
            {
                path: "",
                element: <About />,
            },
            {
                path: "about",
                element: <About />,
            },
            {
                path: "channels",
                element: <Channels />,
            },
            {
                path: "collection/:twitchID",
                element: <Collection />,
            },
            {
                path: "collection",
                element: <Collection />,
            },
            {
                path: "settings",
                element: <Settings />,
            },
            {
                path: "statistics",
                element: <WorkInProgress />,
            },
            {
                path: "trade",
                element: <WorkInProgress />,
            },
            {
                path: "auth",
                element: <WorkInProgress />,
                children: [
                    {
                        path: "login",
                        element: <WorkInProgress />,
                    },
                    {
                        path: "register",
                        element: <WorkInProgress />,
                    },
                ],
            },
        ],
    },
]);

function App() {
    return (
        <ThemeProvider>
            <CollectionProvider>
                <AuthProvider>
                    <DataStoreProvider>
                        <TwitchAPIProvider>
                            <RouterProvider router={router} />
                        </TwitchAPIProvider>
                    </DataStoreProvider>
                </AuthProvider>
            </CollectionProvider>
        </ThemeProvider>
    );
}

export default App;
