import { memo, useEffect, useState, useContext } from "react";
import { IconType } from "react-icons";
import { Link } from "react-router-dom";
import "../styles/HeaderButton.scss";
import { TwitchAPIContext } from "./context/TwitchAPIContext";

export interface HeaderButtonProps {
    icon: IconType;
    link: string;
    label: string;
    selected?: boolean;
    user?: boolean;
}

export const HeaderButton = memo(
    (props: HeaderButtonProps) => {
        const { icon, link, selected = false, user = false } = props;
        const { getUser } = useContext(TwitchAPIContext);
        const label = props.label.replaceAll(" ", "\u00A0");
        const [loading, setLoading] = useState<boolean>(user);
        const [userData, setUserData] = useState<any>(undefined);
        useEffect(() => {
            if (!user) return;
            getUser(label).then((user) => {
                setUserData(user);
                setLoading(false);
            });
        }, [user]);
        if (user) {
            if (loading || userData === undefined) return null;
            return (
                <Link
                    to={link}
                    className={
                        "header-button header-button--profile no-select" +
                        (selected ? " header-button--selected" : "")
                    }>
                    <img
                        src={userData.profile_image_url}
                        className='header-button--profile-icon'
                    />
                    <span className='header-button--tooltip'>
                        <span className='header-button--tooltip-arrow' />
                        {userData.display_name}
                    </span>
                </Link>
            );
        } else {
            return (
                <Link
                    to={link}
                    className={
                        "header-button" +
                        (selected ? " header-button--selected" : "")
                    }>
                    {icon({
                        size: "100%",
                        color: "var(--color-primary-lighter)",
                    })}
                    <span className='header-button--tooltip'>
                        <span className='header-button--tooltip-arrow' />
                        {label}
                    </span>
                </Link>
            );
        }
    },
    (prevProps, nextProps) =>
        prevProps.selected === nextProps.selected &&
        prevProps.user === nextProps.user
);
