import { memo } from "react";
import "../styles/ProfileAbout.scss";

export interface ProfileAboutProps {
    name: string;
    img: string;
    link: string;
}

export const ProfileAbout = memo((props: ProfileAboutProps) => {
    const { name, img, link } = props;

    return (
        <a href={link} className='profile-about'>
            <img
                src={img}
                alt='Profile picture'
                className='profile-about--picture'
            />
            <span className='profile-about--name'>{name}</span>
        </a>
    );
});
