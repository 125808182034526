import { memo, useState, MouseEvent, useEffect, useContext } from "react";
import "../styles/CardUI.scss";
import Sparkle from "../assets/sparkle.gif";
import { DataStore } from "aws-amplify";
import { Card, Rarity, Universe } from "../models";
import { DataStoreContext } from "./context/DataStoreContext";

const CARD_ROTATION = 2;
const MAX_AMOUNT = 5;

export interface CardUIProps {
    id: string;
    name: string;
    state: string;
    cover: string;
    universeID: string;
    color1: string;
    color2: string;
    amount: number;
    rarity: "UNDEFINED" | "COMMON" | "RARE" | "EPIC" | "LEGENDARY";
    informations?: {
        streamer: string;
        date: string;
    }[];
    onClick?: (event: MouseEvent<HTMLDivElement>) => void;
}

type Point = {
    x: number;
    y: number;
};

/* eslint no-var: off */
export const CardUI = memo(
    (props: CardUIProps) => {
        const {
            name,
            state,
            cover,
            universeID,
            color1,
            color2,
            amount,
            rarity,
            onClick,
        } = props;

        const [universe, setUniverse] = useState<string | undefined>(undefined);
        const { isReady } = useContext(DataStoreContext);

        useEffect(() => {
            const fetchUniverse = async () => {
                if (!isReady) return;
                const universe = await DataStore.query(Universe, universeID);
                setUniverse(universe?.icon ?? "");
            };
            fetchUniverse();
        }, [universeID]);

        const [isHovered, setIsHovered] = useState<boolean>(false);
        const [cardRotation, setCardRotation] = useState<Point>({
            x: 0,
            y: 0,
        });
        const [gradientPosition, setGradientPosition] = useState<Point>({
            x: 0,
            y: 0,
        });

        function amountToValue(min: number, max: number): number {
            const a = amount > MAX_AMOUNT ? MAX_AMOUNT : amount;
            return (a * (max - min)) / MAX_AMOUNT + min;
        }

        const handleMouseEvent = (event: MouseEvent) => {
            event.preventDefault();
            if (isHovered) {
                const { clientX, clientY } = event;
                const { left, top, width, height } =
                    event.currentTarget.getBoundingClientRect();
                const x = clientX - left;
                const y = clientY - top;

                // math for mouse position
                var px = Math.abs(Math.floor((100 / width) * x) - 100);
                var py = Math.abs(Math.floor((100 / height) * y) - 100);
                // math for gradient / background positions
                var lp = 50 + (px - 50) / 1.5;
                var tp = 50 + (py - 50) / 1.5;
                var ty = ((tp - 50) / 2) * -1;
                var tx = ((lp - 50) / 1.5) * 0.5;

                setCardRotation({
                    x: tx * CARD_ROTATION,
                    y: ty * CARD_ROTATION,
                });
                setGradientPosition({
                    x: px,
                    y: py,
                });
            }
        };
        if (universe === undefined) return <></>;

        return (
            <div className='card-container' onClick={onClick}>
                <div
                    className={
                        "card no-select" +
                        (isHovered ? "" : " card--idle") +
                        (amount === 0 ? " gray" : "")
                    }
                    onMouseEnter={() => {
                        setIsHovered(true);
                    }}
                    onMouseLeave={() => {
                        setIsHovered(false);
                    }}
                    style={{
                        boxShadow: isHovered
                            ? `
                            -5px -5px ${amountToValue(0, 50)}px -5px ${color1}, 
                            5px 5px ${amountToValue(0, 50)}px -5px ${color2}, 
                            0 55px 35px -20px rgba(0, 0, 0, 0.5)`
                            : `-5px -5px ${amountToValue(
                                  0,
                                  15
                              )}px -5px ${color1}, 
                            5px 5px ${amountToValue(0, 25)}px -5px ${color2},
                            0 0 5px 0px rgba(255,255,255,0),
                            0 55px 35px -20px rgba(0, 0, 0, 0.5)`,
                        outline: isHovered
                            ? `4px solid ${rarityToColor[rarity]}`
                            : `2px solid ${rarityToColor[rarity]}`,
                        transform: isHovered
                            ? `rotateX(${cardRotation.y}deg) rotateY(${cardRotation.x}deg)`
                            : undefined,
                        scale: isHovered ? "1.2" : "1",
                    }}
                    onMouseMove={handleMouseEvent}>
                    <img className='card--cover' src={cover} />
                    <img className='card--universe' src={universe} />
                    <div className='card--bottom'>
                        <span
                            className='card--name'
                            style={{
                                textShadow: "0 0 5px " + rarityToColor[rarity],
                            }}>
                            {name}
                        </span>
                        <span
                            className='card--state'
                            style={{
                                textShadow: "0 0 5px " + rarityToColor[rarity],
                            }}>
                            {state}
                        </span>
                    </div>
                    <div
                        className='card--amount-container'
                        style={{
                            backgroundColor:
                                convertColor(rarityToColor[rarity]) + "80",
                        }}>
                        <span className='card--amount'>{amount}</span>
                    </div>
                    <span
                        className='card--sparkling'
                        style={{
                            backgroundImage: `url(${Sparkle}), linear-gradient(to right bottom, ${color1}, #ffffff40, ${color2})`,
                            opacity: (amount - 1) / (MAX_AMOUNT - 1),
                        }}
                    />
                    <span
                        className='card--gradient'
                        style={{
                            backgroundPosition: `${gradientPosition.x}% ${gradientPosition.y}%`,
                            backgroundImage: `
                            linear-gradient(
                                to right bottom,
                                transparent 25%,
                                ${color1} 49%,
                                ${color2} 51%,
                                transparent 75%)`,
                            opacity: isHovered
                                ? (amount - 1) / (MAX_AMOUNT - 1)
                                : 0,
                        }}
                    />
                </div>
            </div>
        );
    },
    (prevProps, nextProps) => {
        return (
            prevProps.id === nextProps.id &&
            prevProps.name === nextProps.name &&
            prevProps.amount === nextProps.amount &&
            prevProps.state === nextProps.state &&
            prevProps.rarity === nextProps.rarity &&
            prevProps.cover === nextProps.cover &&
            prevProps.universeID === nextProps.universeID &&
            prevProps.color1 === nextProps.color1 &&
            prevProps.color2 === nextProps.color2 &&
            prevProps.onClick === nextProps.onClick
        );
    }
);

export function convertColor(color: string) {
    if (color.startsWith("#")) return color;
    const rgb = color.replace("rgb(", "").replace(")", "").split(",");
    const r = parseInt(rgb[0]);
    const g = parseInt(rgb[1]);
    const b = parseInt(rgb[2]);
    return `#${r.toString(16)}${g.toString(16)}${b.toString(16)}`;
}

export interface UserCardUIProps {
    cardID: string;
    amount: number;
    informations: {
        streamer: string;
        date: string;
    }[];
    card?: Card;
    onClick?: (event: MouseEvent<HTMLDivElement>) => void;
}

export const UserCardUI = memo(
    (props: UserCardUIProps) => {
        const { cardID, amount, informations, onClick } = props;
        const [card, setCard] = useState<Card | undefined>(props.card);
        const { isReady } = useContext(DataStoreContext);

        useEffect(() => {
            const fetchCard = async () => {
                if (!isReady) return;
                const card = await DataStore.query(Card, cardID);
                setCard(card);
            };
            if (card !== undefined && card.id === cardID) return;
            fetchCard();
        }, [cardID]);

        useEffect(() => {
            if (props.card !== undefined) setCard(props.card);
        }, [props.card]);

        if (card === undefined) return <></>;

        return (
            <CardUI
                id={card?.id ?? ""}
                name={card?.name ?? ""}
                state={card?.state ?? ""}
                cover={card?.cover ?? ""}
                universeID={card?.universeID ?? ""}
                color1={card?.color1 ?? "transparent"}
                color2={card?.color2 ?? "transparent"}
                amount={amount}
                rarity={rarityToString(card?.rarity) ?? "UNDEFINED"}
                informations={informations}
                onClick={onClick}
            />
        );
    },
    (prevProps, nextProps) => {
        return (
            prevProps.cardID === nextProps.cardID &&
            prevProps.amount === nextProps.amount &&
            prevProps.informations === nextProps.informations &&
            prevProps.onClick === nextProps.onClick
        );
    }
);

export const rarityToString = (
    rarity: Rarity | string | undefined | null
): "UNDEFINED" | "COMMON" | "RARE" | "EPIC" | "LEGENDARY" => {
    if (typeof rarity === "string") {
        return rarity as "UNDEFINED" | "COMMON" | "RARE" | "EPIC" | "LEGENDARY";
    } else if (rarity === undefined || rarity === null) {
        return "UNDEFINED";
    }
    switch (rarity) {
        case Rarity.COMMON:
            return "COMMON";
        case Rarity.RARE:
            return "RARE";
        case Rarity.EPIC:
            return "EPIC";
        case Rarity.LEGENDARY:
            return "LEGENDARY";
        default:
            return "UNDEFINED";
    }
};

export const rarityToColor = {
    UNDEFINED: "#888888",
    COMMON: "#00d26a",
    RARE: "#0074ba",
    EPIC: "#8d65c5",
    LEGENDARY: "#ff6723",
};

export const rarityToValue = {
    UNDEFINED: 0,
    COMMON: 1,
    RARE: 2,
    EPIC: 3,
    LEGENDARY: 4,
};
