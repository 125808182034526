import { DataStore } from "aws-amplify";
import {
    ProfileChannel,
    ProfileChannelProps,
} from "../components/ProfileChannel";
import { User } from "../models";
import { useContext, useEffect, useState } from "react";
import { DataStoreContext } from "../components/context/DataStoreContext";
import { TwitchAPIContext } from "../components/context/TwitchAPIContext";

export function Channels() {
    const [streamers, setStreamers] = useState<ProfileChannelProps[]>([]);
    const { changeSync, isReady } = useContext(DataStoreContext);
    const { getUser, isStreaming } = useContext(TwitchAPIContext);

    const checkStreamersAreLive = async (streamers: ProfileChannelProps[]) => {
        for (const streamer of streamers) {
            const streaming = await isStreaming(streamer.id);
            streamer.streaming = streaming;
        }
        streamers.sort((a, b) => {
            if (a.streaming && !b.streaming) return -1;
            if (!a.streaming && b.streaming) return 1;
            return 0;
        });
        return streamers;
    };

    useEffect(() => {
        if (!isReady) return;
        if (streamers.length > 0) return;

        const fetchStreamers = async () => {
            const users = await DataStore.query(User, (user) =>
                user.streamer.eq(true)
            );
            let streamers: ProfileChannelProps[] = [];
            for (const user of users) {
                const { id, profile_image_url, display_name, login } =
                    await getUser(user.twitchID);
                streamers.push({
                    id: id,
                    name: display_name,
                    img: profile_image_url,
                    link: `https://www.twitch.tv/${login}`,
                    streaming: false,
                });
            }

            streamers = await checkStreamersAreLive(streamers);
            setStreamers(streamers);
        };
        fetchStreamers();
    }, [isReady]);

    useEffect(() => {
        const interval = setInterval(async () => {
            const newStreamers = await checkStreamersAreLive(streamers);
            setStreamers(newStreamers);
        }, 60000);
        return () => clearInterval(interval);
    }, [streamers]);

    useEffect(() => {
        if (isReady) return;
        changeSync();
    }, []);

    return (
        <>
            <h1>Chaines affiliées au Bot</h1>
            <div className='row-list'>
                {streamers.map((profile, id) => (
                    <ProfileChannel key={id} {...profile} />
                ))}
            </div>
        </>
    );
}
